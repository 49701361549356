import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SENSITIVE_CLASS_NAME } from '@idk-web/core-ui';
import history from '@/history';

const {
  VITE_SENTRY_DSN = '',
  VITE_SENTRY_SAMPLE_RATE = '0.0',
  VITE_SENTRY_TRACES_SAMPLE_RATE = '0.0',
  VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE = '0.0',
  VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE = '0.0',
  VITE_APP_ENV = 'unknown',
  VITE_COMMIT_HASH = 'unknown',
} = import.meta.env;

export function initialize(): void {
  if (!VITE_SENTRY_DSN) {
    console.log('Skipping Sentry initialization: DSN not set');
    return;
  }

  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    release: `idk-web-portal@${VITE_COMMIT_HASH}`,
    environment: VITE_APP_ENV,
    autoSessionTracking: true,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration({
        mask: [SENSITIVE_CLASS_NAME],
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: true,
      }),
    ],
    tracePropagationTargets: ['localhost', 'idkollen.se', /^\//],
    sampleRate: parseFloat(VITE_SENTRY_SAMPLE_RATE),
    tracesSampleRate: parseFloat(VITE_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: parseFloat(
      VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    ),
    replaysOnErrorSampleRate: parseFloat(
      VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
    ),
  });
}

export const SentryRoute = Sentry.withSentryRouting(Route);
